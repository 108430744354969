import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Layout } from '../components';
import { NewsList } from '../components/news/';

const NewsPage: FunctionComponent = () => (
  <>
    <Helmet>
      <title>Ajankohtaista | Treet-verkkoapteekki</title>
      <meta
        name="description"
        content="Ajankohtaista tietoa Treet-verkkoapteekkipalvelusta ja monikanavaisesta asioinnista Avainapteekit-ketjun apteekeissa."
      />
    </Helmet>
    <Layout>
      <NewsList />
    </Layout>
  </>
);

NewsPage.displayName = 'NewsPage';

export default NewsPage;
